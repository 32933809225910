@font-face {
  font-family: "Avenir-Black";
  src: url("assets/fonts/Avenir-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir-Heavy";
  src: url("assets/fonts/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Regular";
  src: url("assets/fonts/Avenir-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Medium";
  src: url("assets/fonts/Avenir-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Roman";
  src: url("assets/fonts/Avenir-Roman.ttf") format("truetype");
}

:root {
  --form-control-color: #111;
  --form-background: #fff;
  --header-background: #111;
  --neutral-white: #fff;
  --white: #fff;
  --grey-200: #f3f3f3;
  --grey-400: #ccc;
  --grey-500: #9b9b9b;
  --grey-600: #808080;
  --grey-700: #4c4b4b;
  --black1: #222722;
  --secondary-bg-modal: rgba(45, 49, 46, 0.8);
  --secondary-bg: #252a26;
  --primary-green-400: #1eaa43;
  --primary-green: #1eaa43;
  --primary-green-200: #59d87a;
  --primary-green-100: #8cf5a8;
  --secondary-progress-bar-bg: rgba(193, 255, 210, 0.2);
  --bg-background: rgba(252, 252, 252, 0.2);
  --secondary-button-bg-secondary: #444a46;
  --red: #ea2b2b;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: 'Google-Sans'; */
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
  /* height: 100%; */
}

body * {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Avenir-Regular";
  font-weight: 400;
}

/* body {
  height: 100%;
} */

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  cursor: pointer;
}
a:active {
  color: unset;
}

img {
  max-width: 100%;
}

.cursor-pt {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

input {
  padding: 0;
}

input::placeholder {
  font-size: 100%;
}

*:focus {
  outline: unset;
}

* {
  scrollbar-width: thin;
}

#root {
  /* height: 100%; */
  background: #1e1e1e;
  /* overflow: scroll; */
  /* display: flex;
  flex-direction: column;
  position: relative; */
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  background: #1e1e1e;
}
.relative {
  position: relative;
}
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.size-0 {
  font-family: "Avenir-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.size-1 {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.size-11 {
  font-family: "Avenir-Roman";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.size-12 {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
}

.size-2 {
  font-family: "Avenir-Roman";
  font-size: 18px;
  line-height: 26px;
  font-style: normal;
  font-weight: 500;
}
.size-21 {
  font-family: "Avenir-Regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-style: normal;
  font-weight: 400;
}
.size-22 {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
}
.size-23 {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
}

.size-3 {
  font-family: "Avenir-Roman";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.size-31 {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.size-32 {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
}

.size-4 {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.size-41 {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}

.size-5 {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
}

.size-6 {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

.size-7 {
  font-family: "Avenir-Black";
  font-style: normal;
  font-weight: 900;
  font-size: 56px;
  line-height: 72px;
}

.color-black {
  color: #000000;
}

.color-white {
  color: #ffff;
}

.color-white-2 {
  color: #f3f3f3;
}

.color-light {
  color: #ffffff80;
}

.color-dark {
  color: #1e1e1e;
}

.color-gray {
  color: #cccccc;
}

.color-purple {
  color: #af47d3;
}

.color-pink {
  color: #ffbefa;
}

.color-red {
  color: #ea2b2b;
}

.color-yellow {
  color: #e6f33f;
}

.color-green {
  color: #59d87a;
}

.color-blue {
  color: #1f84ea;
}
.text-black {
  font-weight: 900;
}

.color-gradient {
  background: linear-gradient(270deg, #ffbefa 0%, #af47d3 48.96%, #4e1bc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.bg-gradient {
  background: linear-gradient(270deg, #ffbefa 0%, #af47d3 48.96%, #4e1bc1 100%);
}

input,
textarea,
input:hover,
input:active,
input:focus,
textarea:hover,
textarea:active,
textarea:focus {
  border: none;
  outline: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

input,
input:hover,
input:focus,
input:active,
button,
button:hover,
button:focus,
button:active {
  background: transparent;
  border: none;
  outline: none;
}
/* input autofill */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
.bg-modal {
  background: linear-gradient(270deg, #f4f3e9 0%, #ec8601 48.96%, #ff0404 100%);
}

.bg-modal-hover:hover {
  background: red;
}

.text-error {
  color: red;
}
.text-italic {
  font-style: italic !important;
}

@media screen and (max-width: 1199px) {
  .size-0 {
    font-family: "Avenir-Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .size-1 {
    font-family: "Avenir-Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .size-11 {
    font-family: "Avenir-Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .size-12 {
    font-family: "Avenir-Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .size-2 {
    font-family: "Avenir-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .size-21 {
    font-family: "Avenir-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
  }
  .size-22 {
    font-family: "Avenir-Heavy";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
  }
  .size-23 {
    font-family: "Avenir-Heavy";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
  }

  .size-3 {
    font-family: "Avenir-Heavy";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }

  .size-31 {
    font-family: "Avenir-Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .size-32 {
    font-family: "Avenir-Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  .size-4 {
    font-family: "Avenir-Heavy";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
  }

  .size-41 {
    font-family: "Avenir-Heavy";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
  }

  .size-5 {
    font-family: "Avenir-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .size-6 {
    font-family: "Avenir-Heavy";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
  }

  .size-7 {
    font-family: "Avenir-Black";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* .size-0 {
    font-family: 'Avenir-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  
  .size-1 {
    font-family: 'Avenir-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  
  .size-11 {
    font-family: 'Avenir-Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  
  .size-12 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
  }
  
  .size-2 {
    font-family: 'Avenir-Roman';
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .size-21 {
    font-family: 'Avenir-Regular', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
  }
  .size-22 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
  }
  .size-23 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
  }
  
  .size-24 {
    font-family: 'Avenir-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .size-25 {
    font-family: 'Avenir-Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  
  .size-3 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  .size-31 {
    font-family: 'Avenir-Roman';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  .size-32 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
  }
  
  
  .size-41 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
  }
  
  .size-5 {
    font-family: 'Avenir-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  
  .size-6 {
    font-family: 'Avenir-Heavy';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
  }
  
  .size-7 {
    font-family: 'Avenir-Black';
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
  } */
}

*::-webkit-scrollbar {
  scrollbar-width: thin;
  /* cursor: pointer; */
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin-top: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #fff !important;
  border-radius: 6px;
}

.scrollbar-horizontal::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin-top: 10px;
}

.scrollbar-horizontal::-webkit-scrollbar-track {
  background: #262626 !important;
  border-radius: 6px;
}

.scrollbar-horizontal::-webkit-scrollbar-thumb {
  background: #888888 !important;
  border-radius: 6px;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}

@keyframes f {
  from {
    clip-path: polygon(
      100% 0,
      100% 0,
      100% 0,
      100% 0,
      0 100%,
      0 100%,
      0 100%,
      0 100%
    );
  }
  20%,
  to {
    clip-path: polygon(
      0 50%,
      0 0,
      100% 0,
      100% 50%,
      0 50%,
      0 100%,
      100% 100%,
      100% 50%
    );
  }
}

.effect-f.inview {
  animation: f 12s;
  animation-timing-function: cubic-bezier();
}

@keyframes fade-in {
  0% {
    opacity: 0;
    filter: blur(1);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.effect-fade.inview {
  animation: fade-in 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.appear {
  transition: all 0.8s;
  opacity: 0;
  transform: translateY(40px);
}
.appear-right {
  transition: all 0.8s;
  opacity: 0;
  transform: translateX(40px);
}
.appear-left {
  transition: all 0.8s;
  opacity: 0;
  transform: translateX(-40px);
}
.appear.inview,
.appear-right.inview,
.appear-left.inview {
  opacity: 1;
  transform: none;
  transition-delay: 0.3s;
}

.nowrap {
  white-space: nowrap;
}
